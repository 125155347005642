import React, { Component } from "react";
import { connect } from "react-redux";
import Rotator from 'exif-auto-rotate';
import { ReactSortable } from "react-sortablejs";
import { updateUser } from "../actions";
import properties from "../data/properties.json";
import categories from "../data/fb-categories.json";
import offerupCategories from "../data/offerup-categories.json";
import clCategories from "../data/cl-categories.json";
import clAreas from "../data/cl-areas.json";
import clSubareas from "../data/cl-subareas.json";
import makes from "../data/makes.json";
import vehicleOptions from "../data/vehicle-options.json";
import mapVehicleCategories from "../data/map-vehicle-categories.json";

const baseUrl = "https://us-central1-lazyposter.cloudfunctions.net"


const getSubcategory2List = (category, subcategory1) => {
    if (category && subcategory1) {
        return categories[category][subcategory1].map(
            s => typeof s === "string" ? s : s["category"]
        )
    }
    return null
}


const getSubcategory3List = (category, subcategory1, subcategory2) => {
    if (category && subcategory1 && subcategory2) {
        let subcategories = []
        for (const subcat of categories[category][subcategory1]) {
            if (typeof subcat !== "string" && subcat.category === subcategory2) {
                subcategories = subcat.subcategories
            }
        }
        return subcategories
    }
    return null
}

class AddListing extends Component {
    constructor(props) {
        super(props)

        const { id } = props.match.params
        const listing = props.user.listings?.filter(l => l.id === id)[0] || {}

        this.state = {
            type: listing.type || "item",
            campaign: listing.campaign || "",

            platform: listing.platform || [],
            title: listing.title || "",
            price: listing.price || 0,
            category: listing.category || "",
            subcategory1: listing.subcategory1 || "",
            subcategory2: listing.subcategory2 || "",
            subcategory3: listing.subcategory3 || "",
            offerupCategory: listing.offerupCategory || "",
            offerupSubcategory: listing.offerupSubcategory || "",
            condition: listing.condition ?? "0",
            description: listing.description || "",
            images: listing.images || [],

            location: listing.location || "",
            zipcode: listing.zipcode || "",
            deliveryMethod: listing.deliveryMethod || "Local pickup only",
            shippingOption: listing.shippingOption || "",
            shippingRate: listing.shippingRate ?? "",
            packageDetails: listing.packageDetails || "",
            shippingCarrier: listing.shippingCarrier || "",
            groups: [...listing.groups || [], ""],
            hideFromFriends: listing.hideFromFriends || false,

            subcategories1: listing?.category ? Object.keys(categories[listing.category]) : [],
            subcategories2: getSubcategory2List(listing.category, listing.subcategory1) || [],
            subcategories3: getSubcategory3List(listing.category, listing.subcategory1, listing.subcategory2) || [],
            offerupSubcategories: listing?.offerupCategory ? offerupCategories[listing.offerupCategory] : [],

            area: listing.area || "",
            subarea: listing.subarea || "",
            phone: listing.phone || "",
            callsOK: listing.callsOK || false,
            smsOK: listing.smsOK || false,
            chatOK: listing.chatOK || false,
            noReplyEmail: listing.noReplyEmail || false,
            craigslistCategory: listing.craigslistCategory || "",
            postingAs: listing.postingAs || "owner",

            dimensions: listing.dimensions || "",
            mobileOS: listing.mobileOS || "",
            frameSize: listing.frameSize || "",
            bicycleType: listing.bicycleType || "",
            wheelSize: listing.wheelSize || "",
            frameMaterial: listing.frameMaterial || "",

            includeMoreAdsLink: listing.includeMoreAdsLink ?? true,


            rentOrSale: listing.rentOrSale || "",
            propertyType: listing.propertyType || "",
            roomOnly: listing.roomOnly || false,
            roommates: listing.roommates || "",
            bathroomType: listing.bathroomType || "",
            bedrooms: listing.bedrooms || "",
            bathrooms: listing.bathrooms || "",
            size: listing.size || "",
            laundry: listing.laundry || "",
            parking: listing.parking || "",
            ac: listing.ac || "",
            heating: listing.heating || "",
            dateAvailable: listing.dateAvailable || "",
            cats: listing.cats || false,
            dogs: listing.dogs || false,

            vehicleType: listing.vehicleType || "",
            make: listing.make || "",
            model: listing.model || "",
            year: listing.year || "",
            mileage: listing.mileage || "",
            bodyStyle: listing.bodyStyle || "",
            colorExt: listing.colorExt || "",
            colorInt: listing.colorInt || "",
            fuel: listing.fuel || "",
            transmission: listing.transmission || "",
            titleStatus: listing.titleStatus || "",
            rvType: listing.rvType || "",
            boatType: listing.boatType || "",
            propulsion: listing.propulsion || "",
            length: listing.length || "",

            stockNo: listing.stockNo || null,

            imageUploadActive: false,
        }

    }

    toBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    uploadImage = async file => {

        const { images } = this.state
        const { user } = this.props

        const image = await Rotator.createRotatedImageAsync(file, "base64")
            .catch(err =>
                (err === "Image is NOT have a exif code" || err === "Image is NOT JPEG")
                    ? this.toBase64(file)
                    : Promise.reject(err)
            ).catch(alert)

        if (!image) {
            return null
        }

        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        const { url } = await fetch(`${baseUrl}/uploadImage?jwt_token=${user.jwt_token}`, {
            //const { url } = await fetch(`http://localhost:8080?jwt_token=${user.jwt_token}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ image })
        })
            .then(response => {
                switch (response.status) {
                    case 401:
                        throw new Error("Subscription expired! Please renew subscription.");
                    case 200:
                        return response.json();
                    default:
                        throw new Error(`error connecting to server: ${response.status} ${response.statusText}`);
                }
            })
            .catch(alert)

        this.setState({ images: [...images, url] })

    }

    onFileSelect = async e => {
        const { files } = e.target

        if (!files.length) {
            return null;
        }

        for (const { size } of files) {
            if (size > 4 * 1024 * 1024) {
                alert("Image size can't be larger than 4 MB")
                e.target.value = ""
                return null;
            }
        }

        this.setState({ imageUploadActive: true })

        for (let i = files.length - 1; i >= 0; i--) {
            await this.uploadImage(files[i])
        }

        this.setState({ imageUploadActive: false })

    }

    removeImage = async img => {
        const { images } = this.state
        this.setState({ images: images.filter(i => i !== img) })

    }

    onSubmit = e => {
        e.preventDefault()
        const formData = this.state
        const { includeMoreAdsLink } = formData
        const { user } = this.props
        const { id } = this.props.match.params
        const listing = user.listings.filter(l => l.id === id)[0]
        const edit = id && listing

        delete formData.subcategories1
        delete formData.subcategories2
        delete formData.subcategories3
        delete formData.offerupSubcategories

        formData.condition = String(formData.condition)
        formData.shippingRate = String(formData.shippingRate)

        formData.groups = formData.groups.map(g => g.trim()).filter(g => g)
        formData.groups = formData.groups.length ? formData.groups : null

        for (const i in formData) {
            if (!formData[i] || !properties[formData.type][i]) {
                delete formData[i]
            }
        }

        if (formData.platform.includes("craigslist")) {
            formData.includeMoreAdsLink = includeMoreAdsLink
        }

        formData.price = Number(formData.price)
        if (formData.type === "item") {
            formData.condition = Number(formData.condition)
            if (formData.shippingRate || formData.shippingRate === 0 || formData.shippingRate === "0") {
                formData.shippingRate = Number(formData.shippingRate)
            }
        }

        if (formData.type === "housing") {
            formData.bedrooms = Number(formData.bedrooms)
            formData.bathrooms = Number(formData.bathrooms)
            if (formData.size) {
                formData.size = Number(formData.size)
            }
            if (formData.roommates) {
                formData.roommates = Number(formData.roommates)
            }
        }

        if (formData.type === "vehicle") {
            formData.mileage = Number(formData.mileage)
        }


        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        fetch(`${baseUrl}/${edit ? "editListing/" + id : "addListing"}?jwt_token=${user.jwt_token}`, {
            //fetch(`http://localhost:8080?jwt_token=${user.jwt_token}`, {
            //fetch(`http://localhost:8080/${id}?jwt_token=${user.jwt_token}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ listing: formData })
        })
            .then(response => {
                switch (response.status) {
                    case 401:
                        throw new Error("Subscription expired! Please renew subscription.");
                    case 200:
                        return response.json();
                    default:
                        throw new Error(`error connecting to server: ${response.status} ${response.statusText}`);
                }
            })
            .then(() => this.props.history.push("/view-listings"))
            .catch(alert)

    }

    facebookOptionsSelected = () => {
        const { platform, category, subcategory1, location, shippingOption, shippingCarrier, packageDetails, shippingRate } = this.state

        if (platform.indexOf("facebook") === -1) {
            return true
        }

        let shippingOptions = true
        if (!this.areShippingOptionsDisabled()) {
            if (shippingOption === "Use a prepaid shipping label") {
                shippingOptions = true && shippingOption && shippingCarrier && packageDetails
            } else {
                shippingOptions = true && shippingOption && (shippingRate || shippingRate === 0)
            }
        }
        return true && category && (subcategory1 || category === "Miscellaneous") && location && shippingOptions
    }

    offerupOptionsSelected = () => {
        const { platform, offerupCategory, offerupSubcategory } = this.state

        if (platform.indexOf("offerup") === -1) {
            return true
        }

        return true && offerupCategory && (offerupSubcategory || offerupCategory === "General")
    }

    craigslistOptionsSelected = () => {
        const { type, platform, title, area, subarea, phone, zipcode, craigslistCategory, mobileOS, frameSize, bicycleType, wheelSize, frameMaterial } = this.state
        const { vehicleType, make, model, year, mileage, fuel, transmission, titleStatus, rvType, boatType, propulsion, length } = this.state

        const baseOptions = true && title && zipcode && (clSubareas[area] ? subarea : true)

        if (platform.indexOf("craigslist") === -1) {
            return true
        }

        if (phone && (isNaN(phone) || phone.length !== 10)) {
            return false
        }

        if (type === "item" && craigslistCategory === "bicycles") {
            return baseOptions && frameSize && bicycleType && wheelSize && frameMaterial
        }

        if (type === "item") {
            return baseOptions && craigslistCategory && (craigslistCategory === "cell phones" ? mobileOS : true)
        }

        if (type === "housing") {
            return baseOptions
        }

        if (type === "vehicle" && vehicleType === "Car/Truck") {
            return baseOptions && make && model && year && mileage && fuel && transmission && titleStatus
        }

        if (type === "vehicle" && vehicleType === "Motorcycle") {
            return baseOptions && make && model && year && mileage && fuel && transmission
        }

        if (type === "vehicle" && vehicleType === "RV/Camper") {
            return baseOptions && make && model && fuel && transmission && rvType
        }

        if (type === "vehicle" && vehicleType === "Powersport") {
            return baseOptions && make && model
        }

        if (type === "vehicle" && vehicleType === "Boat") {
            return baseOptions && boatType && propulsion && length
        }

        if (type === "vehicle" && vehicleType === "Trailer") {
            return baseOptions
        }


        return false
    }

    fbVehicleOptionsSelected = (vehicleType, platform) => {
        if (platform.indexOf("facebook") === -1) {
            return true
        }

        if (vehicleType === "Car/Truck") {
            const { mileage, bodyStyle, colorExt, colorInt } = this.state
            return true && mileage && bodyStyle && colorExt && colorInt
        }

        if (vehicleType === "Motorcycle") {
            const { mileage, colorExt } = this.state
            return true && mileage && colorExt
        }

        if (vehicleType !== "Other" && vehicleType !== "Trailer") {
            const { colorExt, colorInt } = this.state
            return true && colorExt && colorInt
        }

        return true
    }

    isSubmitClickable = () => {
        const { type, platform, title, price, images, imageUploadActive } = this.state
        const { rentOrSale, propertyType, bedrooms, bathrooms, description, roomOnly, roommates, bathroomType } = this.state
        const { vehicleType, make, model, year } = this.state

        if (type === "item") {
            return !imageUploadActive && platform.length && title && title.length < 100 && Number(price) && images.length && this.facebookOptionsSelected() && this.offerupOptionsSelected() && this.craigslistOptionsSelected()
        }
        if (type === "housing" && roomOnly) {
            return !imageUploadActive && platform.length && rentOrSale && propertyType && Number(roommates) && bathroomType && bedrooms && bathrooms && Number(price) && description && images.length && this.craigslistOptionsSelected()
        }
        if (type === "housing") {
            return !imageUploadActive && platform.length && rentOrSale && propertyType && bedrooms && bathrooms && Number(price) && description && images.length && this.craigslistOptionsSelected()
        }
        if (type === "vehicle" && platform.includes("offerup") && platform.length === 1) {
            return !imageUploadActive && title && title.length < 100 && Number(price) && images.length && this.offerupOptionsSelected()
        }
        if (type === "vehicle") {
            return !imageUploadActive && platform.length && vehicleType && make && model && year && Number(price) && this.fbVehicleOptionsSelected(vehicleType, platform) && this.craigslistOptionsSelected()
        }
    }

    areShippingOptionsDisabled = () => {
        const { deliveryMethod } = this.state
        return deliveryMethod === "Local pickup only"
    }

    unsetShippingOptions = (name, value) => {
        if (name === "deliveryMethod" && value === "Local pickup only") {
            this.setState({ shippingOption: "", shippingRate: "", packageDetails: "", shippingCarrier: "" })
        }
        if (name === "shippingOption" && value === "Use a prepaid shipping label") {
            this.setState({ shippingRate: "" })
        }
        if (name === "shippingOption" && value === "Use your own shipping label") {
            this.setState({ packageDetails: "", shippingCarrier: "" })
        }
    }

    onChange = ({ target: { name, value } }) => {

        this.setState({ [name]: value }, () => {
            if (name === "deliveryMethod" || name === "shippingOption") {
                this.unsetShippingOptions(name, value)
            }

            if (name === "vehicleType") {
                this.setState({ offerupSubcategory: mapVehicleCategories[value] })
            }

        })
    }

    onTypeChange = ({ target: { id } }) => {
        this.setState({ type: id, condition: 0 })

        if (id === "vehicle") {
            this.setState({ offerupCategory: "Vehicles", offerupSubcategories: offerupCategories["Vehicles"], condition: "" })
        }
    }

    onPlatformChange = ({ target: { id, checked } }) => {
        let { platform } = this.state

        platform = checked ? [...platform, id] : platform.filter(p => p !== id)

        this.setState({ platform })
    }

    onCategoryChange = ({ target: { value } }) => {
        this.setState({ category: value, subcategory1: "", subcategory2: "", subcategory3: "" })
        this.setState({ subcategories1: Object.keys(categories[value]), subcategories2: [], subcategories3: [] })
    }

    onSubcategory1Change = ({ target: { name, value } }) => {
        const subcategories2 = getSubcategory2List(this.state.category, value)

        this.setState({ subcategory1: value, subcategory2: "", subcategory3: "" })
        this.setState({ subcategories2: subcategories2, subcategories3: [] })
    }

    onSubcategory2Change = ({ target: { name, value } }) => {
        const { category, subcategory1 } = this.state
        const subcategories = getSubcategory3List(category, subcategory1, value)

        this.setState({ subcategory2: value })
        this.setState({ subcategories3: subcategories, subcategory3: "" })
    }


    onOfferupCategoryChange = ({ target: { name, value } }) => {
        const offerupSubcategories = offerupCategories[value].filter(cat => this.state.type === "item" ? !Object.values(mapVehicleCategories).includes(cat) : cat)

        this.setState({ offerupCategory: value, offerupSubcategory: "" })
        this.setState({ offerupSubcategories })
    }

    onGroupChange = (value, i) => {
        const { groups } = this.state
        if (i === groups.length - 1 && groups.length < 20 && value.trim()) { groups.push("") }
        else if (i === groups.length - 2 && !value.trim()) { groups.pop() }
        groups[i] = value
        this.setState({ groups, hideFromFriends: false })
    }


    render = () => {
        const { type, campaign, platform, title, price, condition, description, location, zipcode, images, groups, hideFromFriends, imageUploadActive } = this.state
        const { category, subcategory1, subcategory2, subcategory3, offerupCategory, offerupSubcategory, craigslistCategory } = this.state
        const { subcategories1, subcategories2, subcategories3, offerupSubcategories } = this.state
        const { deliveryMethod, shippingOption, shippingRate, shippingCarrier, packageDetails } = this.state
        const { postingAs, area, subarea, phone, dimensions, mobileOS, frameSize, bicycleType, wheelSize, frameMaterial, includeMoreAdsLink } = this.state
        const { callsOK, smsOK, chatOK, noReplyEmail } = this.state
        const { rentOrSale, propertyType, roomOnly, roommates, bathroomType, bedrooms, bathrooms } = this.state
        const { size, laundry, parking, ac, heating, dateAvailable, cats, dogs } = this.state
        const { vehicleType, make, model, year, mileage, bodyStyle, colorExt, colorInt, fuel, transmission } = this.state
        const { titleStatus, rvType, boatType, propulsion, length } = this.state
        const { subscription_active, subscriptions, types, campaigns, groups: userGroups } = this.props.user
        const { id } = this.props.match.params

        const imageLimit = type === "housing" ? 50 : (type === "vehicle" ? 20 : 10)

        return (

            <div className="container-fluid ps-5" key={id || "default"}>
                <form className="col-5 ms-2" onSubmit={this.onSubmit}>
                    <div className="mb-5">
                        <label htmlFor="campaign" className="form-label mb-1">Campaign</label>
                        <input list="campaigns" defaultValue={campaign || ""} className="form-control" onChange={this.onChange} placeholder="none" name="campaign" disabled={!subscription_active} />
                        <datalist id="campaigns">
                            {
                                campaigns && Object.keys(userGroups) ? Object.keys(campaigns).map(c => (<option key={c} value={c} />)) : null
                            }
                        </datalist>
                        <hr />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="type" className="form-label mb-1">Listing type</label>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="type" id="item" onChange={this.onTypeChange} checked={type === "item"} />
                            <label className="form-check-label" htmlFor="item">
                                item
                            </label>
                        </div>
                        {
                            types.includes("housing") &&
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="type" id="housing" onChange={this.onTypeChange} checked={type === "housing"} disabled={subscriptions.indexOf("facebook") === -1 && subscriptions.indexOf("craigslist") === -1} />
                                <label className="form-check-label" htmlFor="housing">
                                    housing
                                </label>
                            </div>
                        }
                        {
                            types.includes("vehicle") &&
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="type" id="vehicle" onChange={this.onTypeChange} checked={type === "vehicle"} disabled={subscriptions.indexOf("facebook") === -1 && subscriptions.indexOf("craigslist") === -1} />
                                <label className="form-check-label" htmlFor="vehicle">
                                    vehicle
                                </label>
                            </div>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="platform" className="form-label mb-1">Platform(s)</label>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="platform" id="facebook" onChange={this.onPlatformChange} checked={platform.indexOf("facebook") !== -1} disabled={subscriptions.indexOf("facebook") === -1} />
                            <label className="form-check-label" htmlFor="facebook">
                                facebook
                            </label>
                        </div>
                        {
                            type !== "housing" ?
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="platform" id="offerup" onChange={this.onPlatformChange} checked={platform.indexOf("offerup") !== -1} disabled={subscriptions.indexOf("offerup") === -1} />
                                    <label className="form-check-label" htmlFor="offerup">
                                        offerup
                                    </label>
                                </div> : null
                        }
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="platform" id="craigslist" onChange={this.onPlatformChange} checked={platform.indexOf("craigslist") !== -1} disabled={subscriptions.indexOf("craigslist") === -1 || vehicleType === "Commercial/Industrial" || vehicleType === "Other"} />
                            <label className="form-check-label" htmlFor="craigslist">
                                craigslist
                            </label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label mb-1">Title</label>
                        <input type="text" name="title" defaultValue={title} className="form-control" onChange={this.onChange} id="title" aria-describedby="title" disabled={!subscription_active} />
                    </div>

                    {
                        platform.includes("craigslist") && (type === "item" || type === "vehicle" || rentOrSale === "For Sale") &&
                        <div className="mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="postingAs" id="owner" onChange={e => this.setState({ postingAs: e.target.id })} checked={postingAs === "owner"} />
                                <label className="form-check-label" htmlFor="owner">
                                    for sale by owner
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="postingAs" id={type === "housing" ? "broker" : "dealer"} onChange={e => this.setState({ postingAs: e.target.id })} checked={postingAs === "dealer" || postingAs === "broker"} />
                                <label className="form-check-label" htmlFor={type === "housing" ? "broker" : "dealer"}>
                                    for sale by {type === "housing" ? "broker" : "dealer"} (default payment method)
                                </label>
                            </div>
                        </div>
                    }
                    {type === "housing" ?
                        <>
                            <div className="mb-3">
                                <label htmlFor="rentOrSale" className="form-label mb-1">Home for Sale or Rent</label>
                                <select value={rentOrSale} className="form-select" name="rentOrSale" onChange={this.onChange} disabled={!subscription_active}>
                                    <option disabled={true} value="">none</option>
                                    <option value="Rent">Rent</option>
                                    <option value="For Sale">For Sale</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="propertyType" className="form-label mb-1">Property type</label>
                                <select value={propertyType} className="form-select" name="propertyType" onChange={this.onChange} disabled={!subscription_active}>
                                    <option disabled={true} value="">none</option>
                                    <option value="House">House</option>
                                    <option value="Townhouse">Townhouse</option>
                                    <option value="Apartment/condo">Apartment/condo</option>
                                </select>
                            </div>

                            {
                                rentOrSale === "Rent" ?
                                    <div className="mb-3 form-check">
                                        <input className="form-check-input" type="checkbox" name="roomOnly" id="roomOnly" onChange={e => this.setState({ roomOnly: e.target.checked })} checked={roomOnly} />
                                        <label className="form-check-label" htmlFor="roomOnly">
                                            Room only
                                        </label>
                                    </div>
                                    : null
                            }

                            {
                                roomOnly ?
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="roommates" className="form-label mb-1">How many people live here?</label>
                                            <div className="input-group">
                                                <input type="number" name="roommates" min="1" max="20" defaultValue={roommates} className="form-control" onChange={this.onChange} id="roommates" disabled={!subscription_active} />
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="bathroomType" className="form-label mb-1">Bathroom type</label>
                                            <select value={bathroomType} className="form-select" name="bathroomType" onChange={this.onChange} disabled={!subscription_active}>
                                                <option disabled={true} value="">none</option>
                                                <option value="Private">Private</option>
                                                <option value="Shared">Shared</option>
                                            </select>
                                        </div>

                                    </>
                                    : null
                            }

                            <div className="mb-3">
                                <label htmlFor="bedrooms" className="form-label mb-1">Bedrooms</label>
                                <div className="input-group">
                                    <input type="number" name="bedrooms" min="0" max="100" defaultValue={bedrooms} className="form-control" onChange={this.onChange} id="bedrooms" disabled={!subscription_active} />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="bathrooms" className="form-label mb-1">Bathrooms</label>
                                <div className="input-group">
                                    <input type="number" name="bathrooms" min="0" max="100" defaultValue={bathrooms} className="form-control" onChange={this.onChange} id="bathrooms" disabled={!subscription_active} />
                                </div>
                            </div>
                        </>
                        : null
                    }
                    {
                        type === "vehicle" ?
                            <>
                                <div className="mb-3">
                                    <label htmlFor="vehicleType" className="form-label mb-1">Vehicle type</label>
                                    <select value={vehicleType} className="form-select" name="vehicleType" onChange={this.onChange} disabled={!subscription_active}>
                                        <option disabled={true} value="">none</option>
                                        <option value="Car/Truck">Car/Truck</option>
                                        <option value="Motorcycle">Motorcycle</option>
                                        <option value="Powersport">Powersport (atvs, utvs, snowmobiles)</option>
                                        <option value="RV/Camper">RV/Camper</option>
                                        <option value="Trailer">Trailer</option>
                                        <option value="Boat">Boat</option>
                                        <option value="Commercial/Industrial">Commercial/Industrial</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                {
                                    vehicleType === "Car/Truck" || vehicleType === "Motorcycle" ?
                                        <div className="mb-3">
                                            <label htmlFor="make" className="form-label mb-1">Make</label>
                                            <select value={make} className="form-select" name="make" onChange={this.onChange} disabled={!subscription_active}>
                                                <option disabled={true} value="">none</option>
                                                {
                                                    vehicleType ? makes[vehicleType].map(m => (<option key={m} value={m}>{m}</option>))
                                                        : null
                                                }
                                            </select>
                                        </div>
                                        :
                                        <div className="mb-3">
                                            <label htmlFor="make" className="form-label mb-1">Make</label>
                                            <div className="input-group">
                                                <input type="text" name="make" defaultValue={make} className="form-control" onChange={this.onChange} id="make" disabled={!subscription_active} />
                                            </div>
                                        </div>
                                }

                                <div className="mb-3">
                                    <label htmlFor="model" className="form-label mb-1">Model</label>
                                    <input type="text" name="model" defaultValue={model} className="form-control" onChange={this.onChange} id="model" aria-describedby="model" disabled={!subscription_active} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="year" className="form-label mb-1">Year</label>
                                    <div className="input-group">
                                        <input type="number" name="year" min="1950" max="2050" defaultValue={year} className="form-control" onChange={this.onChange} id="year" disabled={!subscription_active} />
                                    </div>
                                </div>
                                {
                                    vehicleType === "Car/Truck" || vehicleType === "Motorcycle" || platform.indexOf("craigslist") !== -1 ?
                                        <div className="mb-3">
                                            <label htmlFor="mileage" className="form-label mb-1">Mileage</label>
                                            <div className="input-group">
                                                <input type="number" name="mileage" min="1000" max="1000000" defaultValue={mileage} className="form-control" onChange={this.onChange} id="mileage" disabled={!subscription_active} />
                                            </div>
                                        </div>
                                        : null
                                }
                            </>
                            : null
                    }
                    <div className="mb-3">
                        <label htmlFor="price" className="form-label mb-1">Price</label>
                        <div className="input-group">
                            <span className="input-group-text">$</span>
                            <input type="number" name="price" min="0" defaultValue={price} className="form-control" onChange={this.onChange} id="price" disabled={!subscription_active} />
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="images" className="form-label">Images</label>
                        <ReactSortable className="d-flex flex-row flex-wrap" list={images} setList={newImages => this.setState({ images: newImages.map(String) })} direction="horizontal" draggable=".flex-column" animation="200" revertOnSpill={true}>

                            {images && images.map(img => (
                                <div key={String(img)} className="col-sm-3 d-flex flex-column pe-3 pb-3">
                                    <img src={String(img)} alt="" className="img-fluid rounded-3" />
                                    <button className="btn btn-link mt-auto" onClick={() => this.removeImage(img)}><small>remove</small></button>
                                </div>
                            ))}
                            {images && images.length < imageLimit ? <div className="col-sm-3 pe-3 pb-3">
                                <div className="img-picker">
                                    <div className={"form-control btn btn-outline-dark btn-block img-upload-btn " + (imageUploadActive ? "disabled" : "")}>
                                        <i className="fas fa-plus"></i>
                                        <input type="file" multiple={true} accept="image/jpeg, image/png, image/bmp, image/tiff, image/gif" id="images" onChange={this.onFileSelect} disabled={!subscription_active && imageUploadActive} />
                                    </div>
                                </div>
                            </div> : <></>
                            }
                        </ReactSortable>
                    </div>

                    {
                        platform.indexOf("offerup") !== -1 ?
                            <>
                                <div className="mb-3">
                                    <label htmlFor="offerupCategory" className="form-label mb-1">Offerup category</label>
                                    <select value={offerupCategory} className="form-select" name="offerupCategory" onChange={this.onOfferupCategoryChange} disabled={!subscription_active || type === "vehicle"}>
                                        <option disabled={true} value="">none</option>
                                        {
                                            type === "vehicle"
                                                ? <option key="Vehicles" value="Vehicles">Vehicles</option>
                                                : Object.keys(offerupCategories).map(cat => (<option key={cat} value={cat}>{cat}</option>))
                                        }
                                    </select>
                                </div>
                                <div className="mb-5">
                                    <label htmlFor="offerupSubcategory" className="form-label mb-1">Offerup subcategory</label>
                                    <select value={offerupSubcategory} className="form-select" name="offerupSubcategory" onChange={this.onChange} disabled={!this.state.offerupSubcategories.length || !subscription_active || type === "vehicle"}>
                                        <option disabled={true} value="">none</option>
                                        {
                                            type === "vehicle"
                                                ? ["Cars & Trucks", "Motorcycles", "Campers & RVs", "Boats & Marine", "Powersport vehicles", "Trailers", "Commercial vehicles", "Other - Vehicles"]
                                                    .map(cat => (<option key={cat} value={cat}>{cat}</option>))
                                                : offerupSubcategories.map(cat => (<option key={cat} value={cat}>{cat}</option>))
                                        }
                                    </select>
                                </div>
                            </>
                            : null
                    }
                    {
                        type === "item" && platform.indexOf("facebook") !== -1 ?
                            <>
                                <div className="mb-3">
                                    <label htmlFor="category" className="form-label mb-1">Facebook category</label>
                                    <select value={category} className="form-select" name="category" onChange={this.onCategoryChange} disabled={!subscription_active}>
                                        <option disabled={true} value="">none</option>
                                        {
                                            Object.keys(categories).map(cat => (<option key={cat} value={cat}>{cat}</option>))
                                        }
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="subcategory1" className="form-label mb-1">Facebook subcategory 1</label>
                                    <select value={subcategory1} className="form-select" name="subcategory1" onChange={this.onSubcategory1Change} disabled={!subcategories1.length || !subscription_active}>
                                        <option disabled={true} value="">none</option>
                                        {
                                            subcategories1.map(cat => (<option key={cat} value={cat}>{cat}</option>))
                                        }
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="subcategory2" className="form-label mb-1">Facebook subcategory 2</label>
                                    <select value={subcategory2} className="form-select" name="subcategory2" onChange={this.onSubcategory2Change} disabled={!subcategories2.length || !subscription_active}>
                                        <option disabled={true} value="">none</option>
                                        {
                                            subcategories2.map(cat => (<option key={cat} value={cat}>{cat}</option>))
                                        }
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="subcategory3" className="form-label mb-1">Facebook subcategory 3</label>
                                    <select value={subcategory3} className="form-select" name="subcategory3" onChange={this.onChange} disabled={!subcategories3.length || !subscription_active}>
                                        <option disabled={true} value="">none</option>
                                        {
                                            subcategories3.map(cat => (<option key={cat} value={cat}>{cat}</option>))
                                        }
                                    </select>
                                </div>
                            </>
                            : null
                    }
                    {
                        type === "item" && platform.indexOf("craigslist") !== -1 ?
                            <div className="mb-3">
                                <label htmlFor="craigslistCategory" className="form-label mb-1">Craigslist category</label>
                                <select value={craigslistCategory} className="form-select" name="craigslistCategory" onChange={this.onChange} disabled={!subscription_active}>
                                    <option disabled={true} value="">none</option>
                                    {
                                        clCategories.map(cat => (<option key={cat} value={cat}>{cat}</option>))
                                    }
                                </select>
                            </div>
                            : null
                    }
                    {
                        type === "item" && (platform.indexOf("facebook") !== -1 || platform.indexOf("craigslist") !== -1) ?

                            <div className="mb-3">
                                <label htmlFor="condition" className="form-label mb-1">Condition</label>
                                <select className="form-select" defaultValue={condition} name="condition" onChange={this.onChange} disabled={!subscription_active}>
                                    <option value="0">New</option>
                                    <option value="1">Used - Like New</option>
                                    <option value="2">Used - Good</option>
                                    <option value="3">Used - Fair</option>
                                </select>
                            </div>
                            : null
                    }
                    {
                        type === "vehicle" ?
                            <>
                                {
                                    vehicleType === "Car/Truck" ?
                                        <div className="mb-3">
                                            <label htmlFor="bodyStyle" className="form-label mb-1">Body style</label>
                                            <select value={bodyStyle} className="form-select" name="bodyStyle" onChange={this.onChange} disabled={!subscription_active}>
                                                <option disabled={true} value="">none</option>
                                                {
                                                    vehicleOptions.bodyStyle.map(cat => (<option key={cat} value={cat}>{cat}</option>))
                                                }
                                            </select>
                                        </div>
                                        : null
                                }
                                {
                                    vehicleType !== "Other" ?
                                        <div className="mb-3">
                                            <label htmlFor="colorExt" className="form-label mb-1">Exterior color</label>
                                            <select value={colorExt} className="form-select" name="colorExt" onChange={this.onChange} disabled={!subscription_active}>
                                                <option disabled={true} value="">none</option>
                                                {
                                                    vehicleOptions.color.map(c => (<option key={c + "-ext"} value={c}>{c}</option>))
                                                }
                                            </select>
                                        </div>
                                        : null
                                }
                                {
                                    vehicleType !== "Motorcycle" && vehicleType !== "Other" && platform.indexOf("facebook") !== -1 ?
                                        <div className="mb-3">
                                            <label htmlFor="colorInt" className="form-label mb-1">Interior color</label>
                                            <select value={colorInt} className="form-select" name="colorInt" onChange={this.onChange} disabled={!subscription_active}>
                                                <option disabled={true} value="">none</option>
                                                {
                                                    vehicleOptions.color.map(c => (<option key={c + "-int"} value={c}>{c}</option>))
                                                }
                                            </select>
                                        </div>
                                        : null
                                }
                                {
                                    vehicleType === "Car/Truck" || platform.indexOf("craigslist") !== -1 ?
                                        <>
                                            <div className="mb-3">
                                                <label htmlFor="condition" className="form-label mb-1">Vehicle condition</label>
                                                <select className="form-select" defaultValue={condition} name="condition" onChange={this.onChange} disabled={!subscription_active}>
                                                    <option disabled={true} value="">not specified</option>
                                                    <option value="0">Excellent</option>
                                                    <option value="1">Very good</option>
                                                    <option value="2">Good</option>
                                                    <option value="3">Fair</option>
                                                    <option value="4">Poor</option>
                                                </select>
                                            </div>
                                            {
                                                platform.indexOf("craigslist") === -1 ?

                                                    <div className="mb-3 form-check">
                                                        <input className="form-check-input" type="checkbox" name="titleStatus" id="titleStatus" onChange={e => this.setState({ titleStatus: e.target.checked ? "clean" : "" })} checked={titleStatus} />
                                                        <label className="form-check-label" htmlFor="titleStatus">
                                                            Clean title
                                                        </label>
                                                    </div>
                                                    : null
                                            }
                                        </>
                                        : null}
                                {
                                    vehicleType !== "Trailer" && vehicleType !== "Other" ?
                                        <div className="mb-3">
                                            <label htmlFor="fuel" className="form-label mb-1">Fuel type</label>
                                            <select value={fuel} className="form-select" name="fuel" onChange={this.onChange} disabled={!subscription_active}>
                                                <option disabled={true} value="">none</option>
                                                {
                                                    vehicleOptions.fuel.map(f => (<option key={f} value={f}>{f}</option>))
                                                }
                                            </select>
                                        </div>
                                        : null
                                }
                                {
                                    vehicleType === "Car/Truck" || (platform.indexOf("craigslist") !== -1 && vehicleType !== "Trailer") ?
                                        <div className="mb-3">
                                            <label htmlFor="transmission" className="form-label mb-1">Transmission</label>
                                            <select value={transmission} className="form-select" name="transmission" onChange={this.onChange} disabled={!subscription_active}>
                                                <option disabled={true} value="">none</option>
                                                <option value="Automatic transmission">Automatic transmission</option>
                                                <option value="Manual transmission">Manual transmission</option>
                                            </select>
                                        </div>
                                        : null
                                }
                                {
                                    platform.indexOf("craigslist") !== -1 && (vehicleType === "Car/Truck" || vehicleType === "Motorcycle") ?
                                        <div className="mb-3">
                                            <label htmlFor="titleStatus" className="form-label mb-1">Title Status</label>
                                            <select value={titleStatus} className="form-select" name="titleStatus" onChange={this.onChange} disabled={!subscription_active}>
                                                <option disabled={true} value="">none</option>
                                                <option value="clean">clean</option>
                                                <option value="salvage">salvage</option>
                                                <option value="rebuilt">rebuilt</option>
                                                <option value="parts only">parts only</option>
                                                <option value="lien">lien</option>
                                                <option value="missing">missing</option>
                                            </select>
                                        </div>
                                        : null
                                }
                                {
                                    platform.indexOf("craigslist") !== -1 && vehicleType === "RV/Camper" ?
                                        <div className="mb-3">
                                            <label htmlFor="rvType" className="form-label mb-1">RV type</label>
                                            <select value={rvType} className="form-select" name="rvType" onChange={this.onChange} disabled={!subscription_active}>
                                                <option disabled={true} value="">none</option>
                                                <option value="class A">class A</option>
                                                <option value="class B">class B</option>
                                                <option value="class C">class C</option>
                                                <option value="fifth wheel trailer">fifth wheel trailer</option>
                                                <option value="travel trailer">travel trailer</option>
                                                <option value="hybrid trailer">hybrid trailer</option>
                                                <option value="folding/popup trailer">folding/popup trailer</option>
                                                <option value="teardrop/compact trailer">teardrop/compact trailer</option>
                                                <option value="toy hauler">toy hauler</option>
                                                <option value="truck camper">truck camper</option>
                                                <option value="other">other</option>
                                            </select>
                                        </div>
                                        : null
                                }
                                {
                                    platform.indexOf("craigslist") !== -1 && vehicleType === "Boat" ?
                                        <>
                                            <div className="mb-3">
                                                <label htmlFor="boatType" className="form-label mb-1">Boat type</label>
                                                <select value={boatType} className="form-select" name="boatType" onChange={this.onChange} disabled={!subscription_active}>
                                                    <option disabled={true} value="">none</option>
                                                    {
                                                        vehicleOptions.boatType.map(f => (<option key={f} value={f}>{f}</option>))
                                                    }
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="propulsion" className="form-label mb-1">Propulsion type</label>
                                                <select value={propulsion} className="form-select" name="propulsion" onChange={this.onChange} disabled={!subscription_active}>
                                                    <option disabled={true} value="">none</option>
                                                    <option value="sail">sail</option>
                                                    <option value="power">power</option>
                                                    <option value="human">human</option>
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="length" className="form-label mb-1">Boat length (LOA)</label>
                                                <div className="input-group">
                                                    <input type="number" name="length" min="1" max="1000" defaultValue={length} className="form-control" onChange={this.onChange} id="length" disabled={!subscription_active} />
                                                </div>
                                            </div>
                                        </>
                                        : null
                                }
                            </>
                            : null
                    }
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label mb-1">Description</label>
                        <textarea name="description" defaultValue={description} className="form-control" onChange={this.onChange} id="description" aria-describedby="description" disabled={!subscription_active} />
                    </div>
                    {
                        platform.indexOf("craigslist") !== -1 ?
                            <div className="mb-3">
                                <label htmlFor="area" className="form-label mb-1">Posting Area (craigslist)</label>
                                <select value={area} className="form-select" name="area" onChange={this.onChange} disabled={!subscription_active}>
                                    <option disabled={true} value="">none</option>
                                    {
                                        Object.keys(clAreas).map(a => (<option key={a} value={a}>{clAreas[a]}</option>))
                                    }
                                </select>
                            </div> : null
                    }
                    {
                        platform.indexOf("craigslist") !== -1 && clSubareas[area] ?
                            <div className="mb-3">
                                <label htmlFor="subarea" className="form-label mb-1">Posting Subarea (craigslist)</label>
                                <select value={subarea} className="form-select" name="subarea" onChange={this.onChange} disabled={!subscription_active}>
                                    <option disabled={true} value="">none</option>
                                    {
                                        clSubareas[area].map(a => (<option key={a} value={a}>{a}</option>))
                                    }
                                </select>
                            </div> : null
                    }
                    {
                        platform.includes("craigslist") &&
                        <>
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label mb-1">Phone number (must be 10 digits)</label>
                                <input type="text" name="phone" defaultValue={phone} className="form-control" onChange={this.onChange} id="frameSize" aria-describedby="frameSize" disabled={!subscription_active} />
                            </div>
                            {
                                phone && <>
                                    <div className="mb-3 form-check">
                                        <input className="form-check-input" type="checkbox" name="callsOK" id="callsOK" onChange={e => this.setState({ callsOK: e.target.checked })} checked={callsOK} />
                                        <label className="form-check-label" htmlFor="callsOK">
                                            phone calls OK
                                        </label>
                                    </div>
                                    <div className="mb-3 form-check">
                                        <input className="form-check-input" type="checkbox" name="smsOK" id="smsOK" onChange={e => this.setState({ smsOK: e.target.checked })} checked={smsOK} />
                                        <label className="form-check-label" htmlFor="smsOK">
                                            sms/text OK
                                        </label>
                                    </div>
                                </>
                            }
                            {
                                type === "item" && postingAs === "owner" && craigslistCategory !== "furniture" &&
                                <div className="mb-3 form-check">
                                    <input className="form-check-input" type="checkbox" name="chatOK" id="chatOK" onChange={e => this.setState({ chatOK: e.target.checked })} checked={chatOK} />
                                    <label className="form-check-label" htmlFor="chatOK">
                                        CL chat OK
                                    </label>
                                </div>
                            }
                            {
                                (postingAs === "dealer" || rentOrSale === "For Sale") &&
                                < div className="mb-3 form-check">
                                    <input className="form-check-input" type="checkbox" name="noReplyEmail" id="noReplyEmail" onChange={e => this.setState({ noReplyEmail: e.target.checked })} checked={noReplyEmail} />
                                    <label className="form-check-label" htmlFor="noReplyEmail">
                                        no replies to email
                                    </label>
                                </div>
                            }
                        </>
                    }
                    {
                        (type === "housing" || platform.includes("facebook") || platform.includes("craigslist")) &&
                        <div className="mb-3">
                            <label htmlFor="location" className="form-label mb-1">Location (City, State)</label>
                            <input type="text" name="location" defaultValue={location} className="form-control" onChange={this.onChange} id="location" aria-describedby="location" disabled={!subscription_active} />
                        </div>
                    }
                    {
                        platform.indexOf("offerup") !== -1 || platform.indexOf("craigslist") !== -1 ?
                            <div className="mb-3">
                                <label htmlFor="zipcode" className="form-label mb-1">Zip code</label>
                                <input type="text" name="zipcode" defaultValue={zipcode} className="form-control" onChange={this.onChange} id="zipcode" aria-describedby="zipcode" disabled={!subscription_active} />
                            </div> : null
                    }
                    {
                        type === "item" && platform.indexOf("facebook") !== -1 ?
                            <>

                                <div className="mb-3">
                                    <label htmlFor="deliveryMethod" className="form-label mb-1">Delivery Method</label>
                                    <select value={deliveryMethod} className="form-select" name="deliveryMethod" onChange={this.onChange} disabled={!subscription_active}>
                                        <option value="Local pickup only">Local pickup only</option>
                                        <option value="Shipping & local pickup">Shipping & local pickup</option>
                                        <option value="Shipping only">Shipping only</option>
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="shippingOption" className="form-label mb-1">Shipping Option</label>
                                    <select value={shippingOption} className="form-select" name="shippingOption" onChange={this.onChange} disabled={this.areShippingOptionsDisabled() || !subscription_active}>
                                        <option disabled={true} value="">none</option>
                                        <option value="Use a prepaid shipping label">Use a prepaid shipping label</option>
                                        <option value="Use your own shipping label">Use your own shipping label</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="shippingRate" className="form-label mb-1">Shipping Rate</label>
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input disabled={deliveryMethod === "Local pickup only" || shippingOption !== "Use your own shipping label" || !subscription_active} type="number" name="shippingRate" defaultValue={shippingRate} className="form-control" onChange={this.onChange} id="price" />
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="packageDetails" className="form-label mb-1">Package Details</label>
                                    <select value={packageDetails} className="form-select" name="packageDetails" onChange={this.onChange} disabled={deliveryMethod === "Local pickup only" || shippingOption !== "Use a prepaid shipping label" || !subscription_active}>
                                        <option disabled={true} value="">none</option>
                                        <option value="< 0.5 lbs">&lt; 0.5 lbs</option>
                                        <option value="0.5-1 lbs">0.5-1 lbs</option>
                                        <option value="1-2 lbs">1-2 lbs</option>
                                        <option value="2-5 lbs">2-5 lbs</option>
                                        <option value="5-10 lbs">5-10 lbs</option>
                                        {/* <option value="10-50 lbs">10-50 lbs</option> */}
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="shippingCarrier" className="form-label mb-1">Shipping Carrier</label>
                                    <select value={shippingCarrier} className="form-select" name="shippingCarrier" onChange={this.onChange} disabled={deliveryMethod === "Local pickup only" || shippingOption !== "Use a prepaid shipping label" || !subscription_active}>
                                        <option disabled={true} value="">none</option>
                                        <option value="USPS ">USPS First Class</option>
                                        <option value="UPS® Ground">UPS® Ground</option>
                                    </select>
                                </div>
                            </>
                            : null
                    }
                    {
                        type === "housing" ?
                            <>
                                <div className="mb-3">
                                    <label htmlFor="size" className="form-label mb-1">Property size (in sq feet)</label>
                                    <div className="input-group">
                                        <input type="number" name="size" min="0" defaultValue={size} className="form-control" onChange={this.onChange} id="size" disabled={!subscription_active} />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="laundry" className="form-label mb-1">Laundry type</label>
                                    <select value={laundry} className="form-select" name="laundry" onChange={this.onChange} disabled={!subscription_active}>
                                        <option value="">none</option>
                                        <option value="In-unit laundry">In-unit laundry</option>
                                        <option value="Laundry in building">Laundry in building</option>
                                        <option value="Laundry available">Laundry available</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="parking" className="form-label mb-1">Parking type</label>
                                    <select value={parking} className="form-select" name="parking" onChange={this.onChange} disabled={!subscription_active}>
                                        <option value="">none</option>
                                        <option value="Garage parking">Garage parking</option>
                                        <option value="Street parking">Street parking</option>
                                        <option value="Off-street parking">Off-street parking</option>
                                        <option value="Parking available">Parking available</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="ac" className="form-label mb-1">Air conditioning type</label>
                                    <select value={ac} className="form-select" name="ac" onChange={this.onChange} disabled={!subscription_active}>
                                        <option value="">none</option>
                                        <option value="Central AC">Central AC</option>
                                        <option value="AC available">AC available</option>
                                    </select>
                                </div>
                                {
                                    platform.includes("facebook") &&
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="heating" className="form-label mb-1">Heating type</label>
                                            <select value={heating} className="form-select" name="heating" onChange={this.onChange} disabled={!subscription_active}>
                                                <option value="">none</option>
                                                <option value="Central heating">Central heating</option>
                                                <option value="Electric heating">Electric heating</option>
                                                <option value="Gas heating">Gas heating</option>
                                                <option value="Radiator heating">Radiator heating</option>
                                                <option value="Heating available">Heating available</option>
                                            </select>
                                        </div>
                                        {rentOrSale === "Rent" &&
                                            <>
                                                <div className="mb-3">
                                                    <label className="form-check-label" htmlFor="dateAvailable">Date available</label>
                                                    <input className="form-control" type="date" name="dateAvailable" id="dateAvailable" onChange={this.onChange} defaultValue={dateAvailable} />
                                                </div>
                                                <div className="mb-3 form-check">
                                                    <input className="form-check-input" type="checkbox" name="cats" id="cats" onChange={e => this.setState({ cats: e.target.checked })} checked={cats} />
                                                    <label className="form-check-label" htmlFor="cats">
                                                        Cat friendly
                                                    </label>
                                                </div>
                                                <div className="mb-3 form-check">
                                                    <input className="form-check-input" type="checkbox" name="dogs" id="dogs" onChange={e => this.setState({ dogs: e.target.checked })} checked={dogs} />
                                                    <label className="form-check-label" htmlFor="dogs">
                                                        Dog friendly
                                                    </label>
                                                </div>
                                            </>}
                                    </>
                                }
                            </>
                            : null
                    }
                    {
                        type === "item" && platform.indexOf("craigslist") !== -1 &&
                        <>
                            <div className="mb-3">
                                <label htmlFor="make" className="form-label mb-1">Make / Manufacturer</label>
                                <input type="text" name="make" defaultValue={make} className="form-control" onChange={this.onChange} id="make" aria-describedby="make" disabled={!subscription_active} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="model" className="form-label mb-1">Model</label>
                                <input type="text" name="model" defaultValue={model} className="form-control" onChange={this.onChange} id="model" aria-describedby="model" disabled={!subscription_active} />
                            </div>
                        </>
                    }
                    {
                        (type === "item" || vehicleType === "Trailer") && platform.includes("craigslist") && craigslistCategory !== "bicycles" &&
                        <div className="mb-3">
                            <label htmlFor="dimensions" className="form-label mb-1">Size / Dimensions</label>
                            <input type="text" name="dimensions" defaultValue={dimensions} className="form-control" onChange={this.onChange} id="dimensions" aria-describedby="dimensions" disabled={!subscription_active} />
                        </div>
                    }
                    {
                        platform.indexOf("craigslist") !== -1 && craigslistCategory === "cell phones" ?
                            <div className="mb-3">
                                <label htmlFor="mobileOS" className="form-label mb-1">Mobile OS</label>
                                <select value={mobileOS} className="form-select" name="mobileOS" onChange={this.onChange} disabled={!subscription_active}>
                                    <option disabled={true} value="">none</option>
                                    <option value="android">android</option>
                                    <option value="apple iOS">apple iOS</option>
                                    <option value="blackberry">blackberry</option>
                                    <option value="windows mobile">windows mobile</option>
                                    <option value="other">other</option>
                                </select>
                            </div>
                            : null
                    }
                    {
                        platform.indexOf("craigslist") !== -1 && craigslistCategory === "bicycles" ?
                            <>
                                <div className="mb-3">
                                    <label htmlFor="frameSize" className="form-label mb-1">Frame size</label>
                                    <input type="text" name="frameSize" defaultValue={frameSize} className="form-control" onChange={this.onChange} id="frameSize" aria-describedby="frameSize" disabled={!subscription_active} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="bicycleType" className="form-label mb-1">Bicycle type</label>
                                    <select value={bicycleType} className="form-select" name="bicycleType" onChange={this.onChange} disabled={!subscription_active}>
                                        <option disabled={true} value="">none</option>
                                        {
                                            vehicleOptions.bicycleType.map(c => (<option key={c} value={c}>{c}</option>))
                                        }
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="wheelSize" className="form-label mb-1">Wheel size</label>
                                    <select value={wheelSize} className="form-select" name="wheelSize" onChange={this.onChange} disabled={!subscription_active}>
                                        <option disabled={true} value="">none</option>
                                        {
                                            vehicleOptions.wheelSize.map(c => (<option key={c} value={c}>{c}</option>))
                                        }
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="frameMaterial" className="form-label mb-1">Bicycle frame material</label>
                                    <select value={frameMaterial} className="form-select" name="frameMaterial" onChange={this.onChange} disabled={!subscription_active}>
                                        <option disabled={true} value="">none</option>
                                        {
                                            vehicleOptions.frameMaterial.map(c => (<option key={c} value={c}>{c}</option>))
                                        }
                                    </select>
                                </div>
                            </>
                            : null
                    }
                    {
                        platform.indexOf("facebook") !== -1 ?
                            <>
                                <div className="mb-3">
                                    <label htmlFor="groups" className="form-label mb-1">List in Facebook Group(s): Copy/paste Exact Title (case sensitive)</label>
                                    {groups.map((group, i) =>
                                        <div key={`group-${i}`}>
                                            <input list="userGroups" type="text" name="groups" defaultValue={group} className="form-control mb-1" onChange={e => this.onGroupChange(e.target.value, i)} id="group" aria-describedby="group" disabled={!subscription_active} />
                                            <datalist id="userGroups">
                                                {
                                                    userGroups && Object.keys(userGroups).length ? Object.keys(userGroups).map(g => (<option key={g} value={g} />)) : null
                                                }
                                            </datalist>
                                        </div>
                                    )}
                                </div>
                                <div className="mb-3 form-check">
                                    <input className="form-check-input" type="checkbox" name="hideFromFriends" id="hideFromFriends" onChange={e => this.setState({ hideFromFriends: e.target.checked })} checked={hideFromFriends} disabled={groups.length > 1} />
                                    <label className="form-check-label" htmlFor="hideFromFriends">
                                        Hide from friends (facebook)
                                    </label>
                                </div>
                            </>
                            : null
                    }

                    {
                        platform.indexOf("craigslist") !== -1 ?
                            <div className="mb-3 form-check">
                                <input className="form-check-input" type="checkbox" name="includeMoreAdsLink" id="includeMoreAdsLink" onChange={e => this.setState({ includeMoreAdsLink: e.target.checked })} checked={includeMoreAdsLink} />
                                <label className="form-check-label" htmlFor="includeMoreAdsLink">
                                    Include "more ads by this user" link (craigslist)
                                </label>
                            </div>
                            : null
                    }

                    <button type="submit" disabled={!this.isSubmitClickable() || !subscription_active} className="btn btn-dark my-3 px-5">Submit</button>
                </form >
            </div >
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    updateUser: (user) => dispatch(updateUser(user)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddListing);